<template>
<div>
<div class="card p-2 bg-card">
     <div class="align-center-flex">
                <div>
                    <input placeholder="Search" class=" mr-bottom form-control input-sm flex" />
                </div>
                <div>
                    <button @click="addrole()" class="btn pointer btn-outline-primary btn-sm margin-right" :disabled="!this.$store.state.auth.permissions.includes('role-create')">+ Add</button>
                </div>
            </div>
</div>
 <b-card>
       <div>
         
           
       
        <div class="card-company-table card card-congratulation-medal user-select">
            <div class="mb-0 table-responsive border rounded">
                <table role="table" class="table b-table table-hover">
                    <thead role="rowgroup header">
                        <tr role="row">
                            <th role="columnheader" scope="col">#</th>
                            <th role="columnheader" scope="col">Name</th>
                            <th role="columnheader" scope="col" class="text-align-right">Action</th>
                        </tr>
                    </thead>
                    <tbody class="rowgroup">
                        <tr v-for=" (role, index) in allRoles" :key="role._id" role="row" class="custom-table-row">
                            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                            <td role="cell">{{ role.name }}</td>
                            <td role="cell" class="align-center flex-display text-align-right">
                                <span @click="viewRoles(role._id)" class="icon-box-new-role pointer">
                                    <feather-icon class="  rotate" icon="EyeIcon" size="20" />
                                </span>
                                <span @click="showModal(role._id)" class="icon-box-new-role pointer">
                                    <feather-icon class=" delete-color rotate" icon="Trash2Icon" size="20" />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="card-body">
                    <b-modal id="modal-danger" ref="modal-danger" ok-only ok-variant="danger" @ok="deleteUser()"
                        modal-class="modal-danger" centered title="Delete !" :ok-disabled="!this.$store.state.auth.permissions.includes('role-delete')">
                        <b-card-text>
                            Are You Sure to Delete the Role ?
                        </b-card-text>
                    </b-modal>
                </div>
            </div>
        </div>
            <div class="align-center-div">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table">
            </b-pagination>
        </div>
    </div>
 </b-card>
 </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BCardText, BSidebar, VBToggle, BPagination, BCard, BModal } from 'bootstrap-vue'
export default {
  data: () => ({
      currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    allRoles:[],
    roleID: ''
  }),
components: {
   BModal, 
   BPagination,
   BCard,
   BCardText

},

props: {
   
},
watch: {
   
},
mounted(){
   this.getRole(this.currentPage)
},
computed: {

},
methods: {
    showModal(itemid) {
        this.roleID = itemid
        this.$refs['modal-danger'].show()
    },
    viewRoles(id){
        this.$router.push({ name: "editrole", params :{id: id}})
    },
    addrole() {
        this.$router.push({ name: "addrole"})
    },
     getRole(num) {

    if ((num !== null) | (num !== 0)) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/roles`,
            params :{page: num}
        };
        this.$http(options)
            .then((res) => {
                this.allRoles = res.data.data
            })
            .catch((err) => {
                console.log(err);
            });
    }
        
    },
    deleteUser(userid) {
        userid = this.roleID
        const options = {
            method: "DELETE",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/roles/${userid}`,
        };
        this.$http(options)
            .then((res) => {
                this.getRole()
            })
            .catch((err) => {
                console.log(err);
            });
    },
    
},

}
</script>

<style>
.header{
    font-size: 20px;
    font-weight: 600;
}
.align-center-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.transpent {
    background-color: rgba(130,134,139,.12)!important
}
.align-center-div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.padding-20 {
    padding: 20px;
}
/* .table:not(.table-dark):not(.table-light) 
thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) 
tfoot:not(.thead-dark) th{
    background-color: #f3f2f7 !important;
    color:#6e6b7b
} */
.align-right {
    display: flex;
    justify-content: flex-end;
}
.delete-color {
    color: #ea5455 !important;
}
.icon-box-new-role {
  width: 25px;
  height: 25px;
  /* background-color: #7367f0; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
} 
.mr-bottom {
    margin-bottom: 10px;
}
.pointer {
    cursor: pointer;
}
.validation {
    color: red;
    font-size: 12px;
    position: relative;
    top: -10px;
}
.table th, .table td {
    border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover{
    background-color: #f6f6f9;
    cursor: pointer;
}
.text-align-right {
    text-align: right;
}
.modal .modal-header .close {
    position: relative;
    top: 4px;
    left: -10px;
}
.user-select {
  user-select: none;
}
th{
  background: #472183 !important;
  color: #fff;
}

</style>
